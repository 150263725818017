import type {FirestoreTimestamp} from '../types/firestore';
import {AffiliateRegexModel} from '../utilities/affilaite';

/*
storeId: DbStoreModel {
    //sub-collections
    managers: DbStoreManagerModel;
    shippingMethods: DbStoreShippingMethodModel;
}

*/

export type StockManagePolicy = 'by-add-to-cart' | 'by-checkout' | 'no-stock-management';
export type BroadcastResolution = '1080p30fps' | '1080p60fps' | '720p30fps' | '720p60fps';

export type DbAffiliateRegexModel = AffiliateRegexModel;
export type CartClearPolicy = 'by-checkout' | 'by-payment';

export enum StoreType {
  DEMO = 'Demo',
  TEST = 'Test',
  LIVE = 'Live',
}

export type CustomCss = {
  liveSession: string;
  shoppableVideo: string;
};

type MimicActionPossibleData = 'product' | 'store' | 'user';
type MimicActionType = string;
type MimicUserDataConfig = {
  [key: MimicActionType]: string;
};
export class DbStoreModel {
  id: string;
  name: string;
  location: string;
  description: string;
  createDate: FirestoreTimestamp;
  title: string;
  url: string;
  externalUrl: string | undefined;
  checkoutRedirectRoute: string | undefined;
  logoUrl: string;
  productsLogoUrl: string;
  emailsLogo: string;
  bannerUrl: string;
  mobileBannerUrl: string;
  measurementsUrl: string;
  taxName?: string;
  taxPercent?: number;
  currencyId: string;
  currency: string;
  priceFormat: string | undefined;
  isPublished: boolean;
  pictureInPicture: boolean;
  mirrorHost: boolean;
  sessionActionsRequireLoginDefault: boolean = false;
  theme?: CustomTheme;
  reminderOptions?: ReminderOptions;
  pixelId?: string | `${string},${string}`;
  mimicUserActions?: MimicUserDataConfig;
  stripe?:
    | {
        publicKey?: string;
        secretKeyFirstChars?: string;
        stripeAccountId?: string;
      }
    | undefined;
  cartClearPolicy: CartClearPolicy;
  stockManagementPolicy: StockManagePolicy;
  broadcastResolution: BroadcastResolution;
  rtdbInfo: StoreRtdbInfo;
  lastSyncTime?: number;
  isAutoCheckoutEnabled?: boolean;
  cronScheduledSync?: string;
  storeConfig?: {
    embed?: {
      hoursBeforeSessionToEmbed?: number;
      embedPostSession?: boolean;
    };
  };
  featureFlags?: {
    enableShoppableVideo?: boolean;
    enableTranscription?: boolean;
    enableTalkingPointsAnalysis?: boolean;
  };
  hostVideoGrid?: boolean;
  storeType?: StoreType;
  customLoaderVideoURL?: string;
  externalCart?: boolean;
  apiToken?: string;
  externalApiUrl?: string;
  defaultLanguage?: string;
  preSessionDesigns?: string;
  allowedDomains?: StoreAllowedDomain[];
  colorPalateSelector?: boolean;
  shopifyApp?: {
    subscriptionPlanId?: string;
    billingEndDate?: string;
    billingStartDate?: string;
    fullyLimited?: boolean;
    partiallyLimited?: boolean;
  };
  isActiveEmbedSessionBtnExpand?: boolean;
  customCss?: CustomCss;
}

export class StoreAllowedDomain {
  id: string;
  addressPattern: string;
  isEnabled: boolean;
  createdAt: FirestoreTimestamp;
  createdBy: string;
  updatedAt: FirestoreTimestamp;
  updatedBy: string;
}

export class StoreRtdbInfo {
  databaseId: string;
  databaseUrl: string;
  state: 'DELETED' | 'DISABLED' | 'ACTIVE';
  lastUpdateToRules?: FirestoreTimestamp;
}

// stores/{storeId}/managers/{userId}
export class DbStoreManagerModel {
  userId: string;
  email: string;
  role: string;
  addDate: FirestoreTimestamp;
  addedByUser: string;
}

// storeUserInvites/{id}
export class DbStoreUserInviteModel {
  id?: string;
  storeId: string;
  email: string;
  active: boolean;
  addedByUserId: string;
  inviteDate: FirestoreTimestamp;
}

export class DbStoreShippingMethodModel {
  id: string;
  storeId: string;
  name: string;
  price?: number;
}

export interface CustomTheme {
  primaryColor?: string;
  secondaryColor?: string;
  lightColor?: string;
}

export interface CoriunderAppKeys {
  merchantnumber: string;
  personalhashkey: string;
}

export class StoreCredential {
  token: string;
  externalUrl: string;
}

export interface ReminderOptions {
  phone: boolean;
  email: boolean;
}

export interface SendReminderData {
  name?: string;
  email?: string;
  phone?: string;
}
